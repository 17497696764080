import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap'
import metamask from '../../Assets/genesis/metamask.png'
import walletconnect from '../../Assets/genesis/walletconnect.png'
import trustwallet from '../../Assets/genesis/trustwallet.png'
import { useWeb3 } from "../../Hooks/UseWeb3";
import { MetamaskWallet, WalletConnect, BinanceWallet } from '../../Hooks/useWallet';
import { getAccount } from "../../Hooks/useAccounts";
import { sleep } from "../../Hooks/useContract";
import { style, iconTheme, position } from '../../Hooks/useToast'
import toast, { Toaster } from 'react-hot-toast';
import lefthead from '../../Assets/genesis/leftarrows.svg';
import righthead from '../../Assets/genesis/rightarrows.svg';

const Connect = (props) => {
    const [connect, setConnect] = useState(true)
    const [accountInfo, setAccountInfo] = useState("");
    const [walletConnect, set_walletConnect] = useState("")

    useEffect(() => {
        setAccountInfo(getAccount);

    }, [accountInfo]);

    const MetaMask = async () => {
        await MetamaskWallet()
        await sleep(1000)
        await useWeb3();
        setAccountInfo(localStorage.getItem("accountInfo"))
        if (localStorage.accountInfo) {
            sleep(500)
            setConnect(false)

            toast.success("Wallet Connected successfully", {
                position: position.position,
                style: style,
                iconTheme: iconTheme,
            })
            setTimeout(() => {
                window.location.reload()
            }, 1000);

        }
    }

    const Binance = async () => {
        await BinanceWallet()
        await sleep(1000)
        await useWeb3();
        setAccountInfo(localStorage.getItem("accountInfo"))
        if (localStorage.accountInfo) {
            setConnect(false)
            toast.success("Wallet Connected successfully", {
                position: position.position,
                style: style,
                iconTheme: iconTheme,
            })
            setTimeout(() => {

                window.location.reload()
            }, 1000);


        }

    }
    const enableWallet = async () => {
        const provider = await WalletConnect()
        set_walletConnect(provider)

        setAccountInfo(localStorage.getItem("accountInfo"))

        if (localStorage.getItem("accountInfo")) {
            setConnect(false)
            toast.success("Wallet Connected successfully", {
                position: position.position,
                style: style,
                iconTheme: iconTheme,
            })

            setTimeout(() => {

                window.location.reload()
            }, 1000);
        }

    }
    return (
        <>
            <Modal show={connect} centered size="md">
                <Modal.Header className='pb-0'>
                    <h5 className='cowa'></h5>
                    <button className='btn btn-link themeclr close' onClick={() => props.onDismiss()}><span className='fa fa-times'></span></button>
                </Modal.Header>
                <Modal.Body className='pt-3 pb-5 px-3 px-sm-5'>
                    <div className='modalheading'><img src={lefthead} className='leftarrows' alt='' /><img src={righthead} className='rightarrows' alt='' /><h5 className='cowa text-center'>Connect Wallet</h5></div>

                    <div className='connect d-flex align-items-center text-center p-4' onClick={() => MetaMask()}>
                        <img src={metamask}  className='me-3'/>
                        <p className='mb-0 mt-2'>Metamask</p>
                    </div>
                    <div className='connect d-flex align-items-center text-center p-4' onClick={enableWallet}>
                        <img src={walletconnect} className='me-3'/>
                        <p className='mb-0 mt-2'>Wallet Connect</p>
                    </div>

                    <div className='connect d-flex align-items-center text-center p-4' onClick={MetaMask}>
                        <img src={trustwallet} className='me-3'/>
                        <p className='mb-0 mt-2'>Trust Wallet</p>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default Connect