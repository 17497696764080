import React, { useEffect, useState } from 'react';
import Footer from './Footers';
import Header from './Header';
import toast, { Toaster } from 'react-hot-toast';
// import Token from "../Assets/images/token.jpg"
// import newdetail from "../Assets/images/newdetail.jpg"
// import Token1 from "../Assets/images/token1.jpg"
import { getNewsDesDataLimit, getNewsDesDataOnedata, UseContact, useContact, useContract } from '../Hooks/UseBackend';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { backurl } from '../Config/env';
import { validateSubscriper, validateUserContact } from '../Hooks/useValidation';
// import coinrobo from "../Assets/images/slide/opengptnews.png";
// import alien from "../Assets/images/alien.svg";
import AOS from 'aos';
import 'aos/dist/aos.css';
import coinrobo from "../Assets/genesis/worldss.png";

const Newsdetails = () => {

    const [newsdata, setnewsdata] = useState({})
    const [newsdesdata, setnewsdesdata] = useState([])
    const [name, setname] = useState("");
    const [Email, setEmail] = useState("");
    const [Comments, setComments] = useState("");
    const [website, setwebsite] = useState("");
    const [errors, seterrors] = useState({})

    useEffect(() => {
        getData()
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [])

    useEffect(() => {
        AOS.init();
        AOS.refresh();

    }, [])

    const getData = async () => {
        var newsid = window.location.pathname.split("/")[2];
        const data = await getNewsDesDataOnedata(newsid);

        setnewsdata(data)

        const newsLimit = await getNewsDesDataLimit();
        setnewsdesdata(newsLimit)

    }



    const onSubmit = async (e) => {

        e.preventDefault();
        const UserData = {
            name: name,
            Email: Email,
            Comments: Comments,
            website: website,



        };

        const { errors, isValid } = await validateSubscriper(UserData);
        if (!isValid) {

            seterrors(errors)
        }
        else {

            await UseContact(UserData)

        }



    };
    return (
        <div className="news">
            <Header />
            <div><Toaster /></div>
            <div className='newshead pb-5'>


                <div className='allsection'>

                    {/* <div className='newdetail details position-relative' data-aos="fade-down" data-aos-duration="1000">
                        <div class="lines">
                            <div class="line1"></div>
                            <div class="line1"></div>
                            <div class="line1"></div>
                            <div class="line1"></div>
                        </div>
                        <img src={coinrobo} />
                    </div> */}

                    <div className='container container-theme'>
                        <div className='row'>
                            <div className='col-md-8 mx-auto mt-3 mb-4'>
                                  <img src={backurl + `/Images/${newsdata && newsdata?.logoURI}`} />
                                {/* <img src={coinrobo} className='img-fluid w-100' /> */}
                            </div>
                        </div>
                    </div>

                </div>
                <div className='container container-theme'>

                    <div className='row'>
                        <div className='col-lg-9 col-md-7 col-12'>
                            <div className='newdetailsec position-relative'>
                                <span className='greengradientleft'></span>

                                <h2 data-aos="fade-right" data-aos-duration="1000">{newsdata?.Title}</h2>

                                {/* <h2 data-aos="fade-right" data-aos-duration="1000">{newsdata?.Title}</h2> */}
                                {/* <h2 data-aos="fade-right" data-aos-duration="1000">Genesis Technical Goals</h2> */}

                                <div className='dayscmment'>
                                    {/* <p><i class="fas fa-calendar"></i> {newsdata && newsdata?.updatedAt?.substring(0,10)}</p> */}
                                    {/* <p><i class="fas fa-comments"></i> Add Comment</p> */}
                                </div>
                                <p className='textconete'>
                                    <b> {newsdata?.Heading}</b>
                                    {/* <b> Genesis</b> */}
                                </p>
                                {/* <p className='textconete'>
                        {ReactHtmlParser(newsdata?.Description)}
                        </p> */}
                                <p className='textconete' data-aos="fade-right" data-aos-duration="1000">
                                    {ReactHtmlParser(newsdata?.Description)}                              
                                    {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                        <br></br><br></br>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                        <br></br><br></br>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. */}
                                </p>


                                {newsdata?.NewsLink === null || newsdata?.NewsLink === "" ? <></> :
                                    <div className='vdieo_hide no-bord'>
                                        <iframe src={newsdata?.NewsLink}>
                                        </iframe>



                                    </div>}

                                {newsdata?.VimeoLink === null || newsdata?.VimeoLink === "" ? <></> :
                                    <div className='vdieo_hide no-bord'>
                                        <iframe src={newsdata?.VimeoLink}>
                                        </iframe>



                                    </div>}



                            </div>
                            {/* <div className='form_loist'>
                        <h2>Add Comment</h2>
                      <form onSubmit={onSubmit}>
                       <div>
                       <div className='labelforme'>
                       <label>Comment</label>
                        <textarea  
                        value={Comments}
                        onChange={(e) => { setComments(e.target.value)}}rows="4" >
                            
                        </textarea>
                       <span className="text-danger f-12 d-block text-left">{errors.Comments}</span>
                       </div>

                       <div className='inputfoe'>
                       <div className='labelforme'>
                       <label>Name</label>
                        <input 
                        type="text"
                        value={name}
                        onChange={(e) => { setname(e.target.value)}}></input>
                       <span className="text-danger f-12 d-block text-left">{errors.name}</span>
                       </div>

                       <div className='labelforme'>
                       <label>Email</label>
                        <input 
                        value={Email}
                        onChange={(e) => { setEmail(e.target.value)}}
                        type="text"/>
                       <span className="text-danger f-12 d-block text-left">{errors.Email}</span>
                       </div>

                       <div className='labelforme'>
                       <label>Website</label>
                        <input 
                        type="text"
                        value={website}
                                          onChange={(e) => { setwebsite(e.target.value)}}/>
                       <span className="text-danger f-12 d-block text-left">{errors.website}</span>
                       </div>

                       </div>
                       </div>
                       <div className='labelformecheck mt-3'>
                      
                      
                       </div>
                       <button  className='submitfomer'>Submit Comment</button>
                      </form>
                    </div> */}
                        </div>
                        <div className='col-lg-3 col-md-5 col-12'>
                            <div className='recent_post'>
                                <h2>Recent Post</h2>
                                {newsdesdata && newsdesdata.map((item) =>
                                (item.status == true ?
                                    <div className='recentblogdet'>

                                        <div className='recentdeis'>

                                            <img className='img-fluid' src={backurl + `/Images/${item?.logoURI}`} alt='alirobo' />
                                            {/* <img className='img-fluid' src={coinrobo} alt='alirobo' /> */}
                                        </div>


                                        {/* <a href={`/newsdetails/${item._id}`}>
                                            {item?.Title}

                                        </a> */}

                                        <a href={`/newsdetails/${item._id}`}>
                                        {item?.Title}

                                        </a>

                                    </div> : <></>)
                                )}


                            </div>
                            {/* {newsdata?.NewsLink === "" ? <></> :
                            <div className='vdieo_hide'>
                                <iframe src={newsdata?.NewsLink}>
                                </iframe>



                            </div>}

                            {newsdata?.VimeoLink === "" ? <></> :
                            <div className='vdieo_hide'>
                                <iframe src={newsdata?.VimeoLink}>
                                </iframe>



                            </div>} */}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Newsdetails