import React, { useEffect, useState } from 'react';
import Footer from './Footers';
import Header from './Header';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import lefthead from '../Assets/genesis/lefthead.png';
import righthead from '../Assets/genesis/righthead.png';
// import Token from "../Assets/images/token.jpg"

import { getArticles, getNewsData, getNewsDesData, getTotalArticleImage } from '../Hooks/UseBackend';
import { backurl } from '../Config/env';


import coinrobo from "../Assets/genesis/worldss.png";
// import Token1 from "../Assets/images/token1.jpg";

import articleimg from "../Assets/genesis/robos.png";

import AOS from 'aos';
import 'aos/dist/aos.css';
const News = () => {


    const [newsdata, setnewsdata] = useState([])
    const [newsdesdata, setnewsdesdata] = useState([])
    const [articledata, setarticledata] = useState([])
    const responsive2 = {
        0: {
            items: 1,
        },
        424: {
            items: 1,
        },
        576: {
            items: 1,
        },
        768: {
            items: 1,
        },
        1200: {
            items: 1,
        },
    }

    const responsive3 = {
        0: {
            items: 1,
        },
        768: {
            items: 2,
        },
        1200: {
            items: 3,
        },
    }

    useEffect(() => {
        getData()
    }, []
    )
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, [])
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
          });
    }, [])


    const getData = async () => {

        const news = await getNewsData();
        setnewsdata(news)

        const newsdes = await getNewsDesData();
        var sorting =  newsdes.sort(
            (p1, p2) => (p1.preference < p2.preference) ? 1 : (p1.preference > p2.preference) ? -1 : 0);
            setnewsdesdata(sorting)
            console.log("sorting",sorting);

        const articles = await getArticles();
        var sorting =  articles.sort(
            (p1, p2) => (p1.preference < p2.preference) ? 1 : (p1.preference > p2.preference) ? -1 : 0);

        // setarticledata(articles)
        const Imagedata = await getTotalArticleImage();
        var datsd = articles.map((it, index) => {
            it.image = Imagedata[index]

            return it;
        })
console.log("datsddatsd",datsd);

        setarticledata(datsd)


    }
    return (
        <div className="news">
            <Header />

            {/* <div className='h-100vh'></div> */}
            
            <div className=''>
            <div className='newshead'>

                <OwlCarousel items={1} className="owl-theme" loop={false} nav={true} margin={0} autoplay={false} responsive={responsive2} dots={false}>
                    {newsdata && newsdata.map((item) =>
                    (item.status == true ?
                        <div className='items'>
                            <div className='newshead'>
                                <div className='newdetail'>
                                    <img className='newsbackimg' src={backurl + `/Images/${item?.logoURI}`}/>
                                    {/* <img className='newsbackimg' src={smart} /> */}
                                </div>

                            </div>
                            <div className='container container-theme mt-4'>
                                <div className='itemsfles'>
                                    <h2>{item?.Heading}</h2> 
                                   <p>{item?.Description}</p>
                                    {/* <h2>Welcome to Genesis</h2>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p> */}
                                </div>
                            </div>
                        </div> : <></>)
                    )}

                </OwlCarousel>
            </div>
            <div className='Editor_pick firstsec position-relative'>
                <span className='greengradientleft'></span>
                <span className='greengradientright'></span>
                <span className='violentgradient'></span>
                <div class="lines">
                    <div class="line1"></div>
                    <div class="line1"></div>
                    <div class="line1"></div>
                </div>
                <div className='container container-theme mt-4'>
                    {/* <h2>Editor's pick</h2> */}
                    <div className='alltitle'><img src={lefthead} className='lefthead' alt='' /><img src={righthead} className='righthead' alt='' /> <h1 className='h1tag'>{"{Editors Pick}"}</h1></div>
                    <div className='row'>
                        {/* {[...Array(6)].map(()=>( */}
                        {newsdesdata && newsdesdata.map((item) =>
                        (item.status == true ?
                            <div className='col-lg-4 col-md-6 col-sm-6 col-12 col_token mb-4'
                            // data-aos="fade-down" data-aos-duration="1500"
                            >
                                <a href={`/newsdetails/${item._id}`}>
                                    <div className='token_list moving-border'>
                                        <div className='tokenimg'>
                                            {/* <img src={coinrobo} className='mt-3' /> */}
                                            <img src={backurl + `/Images/${item?.logoURI}`} />
                                        </div>
                                        <div className='token_items'>
                                            <h2>{item?.Title}</h2>
                                            <p>{item?.Heading}</p>
                                            {/* <h2>Genesis Technical Goals</h2>
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text </p> */}
                                        </div>
                                        {/* <span class="top"></span>
                                        <span class="right"></span>
                                        <span class="bottom"></span>
                                        <span class="left"></span> */}
                                    </div>
                                </a>
                            </div> : <></>)


                        )}
                    </div>

                </div>
            </div>

            <section className='Editor_pick launchpad position-relative' id='latest_article'>
                <span className='greengradientleft'></span>
                <span className='greengradientright'></span>
                <span className='violentgradient'></span>
                <div class="lines">
                    <div class="line1"></div>
                    <div class="line1"></div>
                    <div class="line1"></div>
                </div>
                <div className='container container-theme mt-4'>
                    {/* <h2>Latest Articles</h2> */}
                    <div className='alltitle'><img src={lefthead} className='lefthead' alt='' /><img src={righthead} className='righthead' alt='' /> <h1 className='h1tag'>{"{Latest Articles}"}</h1></div>

                </div>
                <div className='container carouselcontainer'>
                    {articledata && articledata.length > 0 &&
                        <OwlCarousel items={3} className="owl-theme latestarticle" loop={false} nav={true} margin={10} autoplay={false} responsive={responsive3} dots={false}>
                            {articledata && articledata.map((item) =>
                                item.status == true ?
                                    <a href={`/latestarticles/${item && item?._id}`} className="col_token">
                                        <div className='token_list'
                                        // data-aos="zoom-in"
                                        >
                                            <div className='tokenimg'>
                                                <img src={backurl + `/Images/${item && item?.logoURI}`} />
                                                {/* <img src={articleimg} /> */}
                                            </div>
                                            <div className='token_items'>
                                                <h2>{item && item?.Title}</h2>
                                                <p>{item && item?.Heading}</p>
                                                {/* <h2>Genesis Technical Goals</h2>
                                                <p>Genesis </p> */}
                                            </div>
                                            {/* <span class="top"></span>
                                            <span class="right"></span>
                                            <span class="bottom"></span>
                                            <span class="left"></span> */}
                                        </div>
                                    </a> : <></>
                            )}
                        </OwlCarousel>

                    }





                    {/* {articledata && articledata[1]?.status == true ?
                        <a href={`/latestarticles/${articledata && articledata[1]?._id}`} className="col_token">
                        <div className='token_list '>
                        <div className='tokenimg'>
                        <img src={backurl + `/Images/${articledata && articledata[1]?.logoURI}`}/>
                        </div>
                        <div className='token_items'>
                            <h2>{articledata && articledata[1]?.Title}</h2>
                            <p>{articledata && articledata[1]?.Heading}</p>
                        </div>
                    </div>
                         </a> : <></>}
                        {articledata && articledata[2]?.status == true ?  
                        <a href={`/latestarticles/${articledata && articledata[2]?._id}`} className="col_token"> 
                        <div className='token_list '>
                        <div className='tokenimg'>
                        <img src={backurl + `/Images/${articledata && articledata[2]?.logoURI}`}/>
                        </div>
                        <div className='token_items'>
                            <h2>{articledata && articledata[2]?.Title}</h2>
                            <p>{articledata && articledata[2]?.Heading}</p>
                        </div>
                    </div>
                        </a> : <></>}
                        {articledata && articledata[3]?.status == true ?
                        <a href={`/latestarticles/${articledata && articledata[3]?._id}`} className="col_token">
                        <div className='token_list'>
                        <div className='tokenimg'>
                        <img src={backurl + `/Images/${articledata && articledata[3]?.logoURI}`}/>
                        </div>
                        <div className='token_items'>
                            <h2>{articledata && articledata[3]?.Title}</h2>
                            <p>{articledata && articledata[3]?.Heading}</p>
                        </div>
                    </div>
                        </a> : <></>} */}
                </div>



            </section>
            </div>

          

            <Footer />
        </div>
    )
}

export default News