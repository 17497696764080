import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import toast, { Toaster } from 'react-hot-toast';

import { getArticle1Data, getArticle2Data, getArticle3Data, getArticle4Data, getNewsDesDataLimit, getNewsDesDataOnedata, getOneArticlesdata, getTotalArticleImage, UseContact, useContact, useContract } from '../Hooks/UseBackend';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { backurl } from '../Config/env';
import { validateSubscriper, validateUserContact } from '../Hooks/useValidation';
import articleimg from "../Assets/genesis/robos.png";
import coinrobo from "../Assets/genesis/worldss.png";
import AOS from 'aos';
import 'aos/dist/aos.css';
const Newsdetails = () => {

    const [newsdata, setnewsdata] = useState({})
    const [newsdesdata, setnewsdesdata] = useState([])
    const [name, setname] = useState("");
    const [Email, setEmail] = useState("");
    const [Comments, setComments] = useState("");
    const [website, setwebsite] = useState("");
    const [errors, seterrors] = useState({})
    const [ArticleImage1, setArticleImage1] = useState([])
    const [ArticleImage2, setArticleImage2] = useState([])
    const [ArticleImage3, setArticleImage3] = useState([])
    const [ArticleImage4, setArticleImage4] = useState([])
    const [newid, setnewsid] = useState("")

    useEffect(() => {
        AOS.init();
        AOS.refresh();
        getData()
    }, [])

    const getData = async () => {
        var newsid = window.location.pathname.split("/")[2];
        setnewsid(newsid)
        const data = await getOneArticlesdata(newsid);
        console.log("dat", data, String(data)?.includes('<br>'));
        setnewsdata(data)

        const newsLimit = await getNewsDesDataLimit();

        setnewsdesdata(newsLimit)


        const data1 = await getArticle1Data();

        setArticleImage1(data1)
        const data2 = await getArticle2Data();
        setArticleImage2(data2)
        const data3 = await getArticle3Data();
        setArticleImage3(data3)
        const data4 = await getArticle4Data();
        setArticleImage4(data4)



    }



    const onSubmit = async (e) => {

        e.preventDefault();
        const UserData = {
            name: name,
            Email: Email,
            Comments: Comments,
            website: website,



        };

        const { errors, isValid } = await validateSubscriper(UserData);
        if (!isValid) {

            seterrors(errors)
        }
        else {

            await UseContact(UserData)

        }



    };
    return (
        <div className="news">
            <Header />
            <div><Toaster /></div>
            <div className='newshead'>


                <div className='allsection'>

                    <div className='container container-theme'>
                        <div className='row'>
                            <div className='col-md-8 mx-auto mt-3 mb-4'>
                                {/* <img src={backurl + `/Images/${newsdata && newsdata?.logoURI}`}/> */}
                                <img src={articleimg} className='img-fluid w-100' />
                            </div>
                        </div>
                    </div>

                    {/* <div className='newdetail'>
                    <img src={backurl + `/Images/${newsdata && newsdata?.logoURI}`}/>
                    <img src={articleimg} />
                </div> */}

                </div>
                <div className='container container-theme'>

                    <div className='row'>

                        <div className='col-lg-9 col-md-7 col-12'>
                            <div className='newdetailsec'>
                                {/* <h2>{newsdata?.Title}</h2> */}

                                {/* <h2 data-aos="fade-right" data-aos-duration="1000">{newsdata?.Title}</h2> */}

                                <h2 data-aos="fade-right" data-aos-duration="1000">Genesis Articles</h2>

                                <div className='dayscmment'>
                                    {/* <p><i class="fas fa-calendar"></i> {newsdata && newsdata?.updatedAt?.substring(0,10)}</p> */}
                                    {/* <p><i class="fas fa-comments"></i> Add Comment</p> */}
                                </div>
                                <p data-aos="fade-right" data-aos-duration="1000" className='textconete'>
                                    {/* <b>{newsdata?.Heading}</b> */}
                                    <b> Genesis</b>
                                </p>
                                {/* {newsdata?.Description != null ?
                        <>   <p className='textconete'>
                        {ReactHtmlParser(newsdata?.Description)}
                        </p>
                        {newsdata?.NewsLink === null ? <></> :
                        <div className='vdieo_hide'>
                    <iframe src={newsdata?.NewsLink}>
</iframe>
                        
                    </div>}
                    </> :<></>} */}

                                <p data-aos="fade-right" data-aos-duration="1000" className='textconete'>
                                
                                    {/* {ReactHtmlParser(newsdata?.Description)}
                                    {newsdata?.NewsLink === null || newsdata?.NewsLink === "" ? <></> :
                                        <div className='vdieo_hide no-bord'>
                                            <iframe src={newsdata?.NewsLink}>
                                            </iframe>

                                        </div>}
                                    {newsdata?.VimeoLink === null || newsdata?.VimeoLink === "" ? <></> :
                                        <div className='vdieo_hide no-bord'>
                                            <iframe src={newsdata?.VimeoLink}>
                                            </iframe>

                                        </div>} */}

                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                        <br></br><br></br>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                        <br></br><br></br>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </p>



                                {newsdata?.Description1 != null && !(String(newsdata?.Description1)?.includes('<br>')) ?
                                    <>            <p className='textconete'>
                                        {ReactHtmlParser(newsdata?.Description1)}
                                    </p>
                                        {newsdata?.NewsLink1 === null || newsdata?.NewsLink1 === "" ? <></> :
                                            <div className='vdieo_hide no-bord'>
                                                <iframe src={newsdata?.NewsLink1}>
                                                </iframe>


                                            </div>}</> : <></>}
                                {newsdata?.VimeoLink1 === null || newsdata?.VimeoLink1 === "" ? <></> :
                                    <div className='vdieo_hide no-bord'>
                                        <iframe src={newsdata?.VimeoLink1}>
                                        </iframe>

                                    </div>}

                                {newsdata?.Description2 != null && !(String(newsdata?.Description2)?.includes('<br>')) ?
                                    <>         <p className='textconete'>
                                        {ReactHtmlParser(newsdata?.Description2)}
                                    </p>
                                        {newsdata?.NewsLink2 === null || newsdata?.NewsLink2 === "" ? <></> :
                                            <div className='vdieo_hide no-bord'>
                                                <iframe src={newsdata?.NewsLink2}>
                                                </iframe>

                                            </div>}</> : <></>
                                }
                                {newsdata?.VimeoLink2 === null || newsdata?.VimeoLink2 === "" ? <></> :
                                    <div className='vdieo_hide no-bord'>
                                        <iframe src={newsdata?.VimeoLink2}>
                                        </iframe>

                                    </div>}


                                {(newsdata?.Description3 != null && !(String(newsdata?.Description3)?.includes('<br>'))) ?
                                    <>    <p className='textconete'>
                                        {ReactHtmlParser(newsdata?.Description3)}
                                    </p>
                                        {newsdata?.NewsLink3 === null || newsdata?.NewsLink3 === "" ? <></> :
                                            <div className='vdieo_hide no-bord'>
                                                <iframe src={newsdata?.NewsLink3}>
                                                </iframe>

                                            </div>}</> : <></>}

                                {newsdata?.VimeoLink3 === null || newsdata?.VimeoLink3 === "" ? <></> :
                                    <div className='vdieo_hide no-bord'>
                                        <iframe src={newsdata?.VimeoLink3}>
                                        </iframe>

                                    </div>}

                                {console.log("textconete", String(newsdata?.Description3)?.includes('<br>'), newsdata?.Description3, newsdata?.Description2, newsdata?.Description1)}


                                <div class="imgdive">

                                    {/* { newid === "6388ab18dd975d15eb883927" ? 
       ArticleImage1 && ArticleImage1.map((item) =>{
       <div class="imgs">
           <img src={backurl + `/Images/${item?.logoURI}`} />
       </div>})
        : newid === "6388ab2cdd975d15eb883948"  ?  
        <div class="imgs">
        <img src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg" />
    </div> : newid === "6388b3bb06417923d0ea2a8a"  ? 
     <div class="imgs">
        <img src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg" />
    </div> : <div class="imgs">
        <img src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg" />
    </div>} */}


                                    {/* {newid === "639328781f7a1cf17802ac6a" && ArticleImage1.map((item) =>
                                    <div class="imgs">
                                        <img src={backurl + `/Images/${item?.logoURI}`} />
                                    </div>)}
                                {newid === "639328811f7a1cf17802ac6f" && ArticleImage2.map((item) =>
                                    <div class="imgs">
                                        <img src={backurl + `/Images/${item?.logoURI}`} />
                                    </div>)}
                                {newid === "639328891f7a1cf17802ac74" && ArticleImage3.map((item) =>
                                    <div class="imgs">
                                        <img src={backurl + `/Images/${item?.logoURI}`} />
                                    </div>)}
                                {newid === "639335481f7a1cf17802b065" && ArticleImage4.map((item) =>
                                    <div class="imgs">
                                        <img src={backurl + `/Images/${item?.logoURI}`} />
                                    </div>)} */}



                                    {/* <div class="imgs">
           <img src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg" />
       </div>
       <div class="imgs">
           <img src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg" />
       </div>
       <div class="imgs">
           <img src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg" />
       </div>
       <div class="imgs">
           <img src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg" />
       </div> */}
                                </div>
                            </div>

                        </div>
                        <div className='col-lg-3 col-md-5 col-12'>
                            <div className='recent_post'>
                                <h2>Recent News Post</h2>
                                {newsdesdata && newsdesdata.map((item) =>
                                (item.status == true ?
                                    <div className='recentblogdet'>

                                        <div className='recentdeis'>
                                            <img src={backurl + `/Images/${item?.logoURI}`} />
                                            {/* <img src={articleimg} /> */}
                                        </div>

                                        {/* <a href={`/newsdetails/${item._id}`}>Genesis Advanced coding</a> */}
                                        <a href={`/newsdetails/${item._id}`}>{item?.Title}</a>

                                    </div> : <></>)
                                )}


                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )
}

export default Newsdetails