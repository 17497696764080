export const  style = {                     
    minWidth: '300px',
    minHeight: '55px',
    border: '2px solid #ffffffba',
    padding: '16px',
    color: '#fff',
    background: '#000',
    
}

export const iconTheme = {
    primary: '#57fff1' ,
    secondary: '#000',
}

export const  position= {
    position: "bottom-center"
}