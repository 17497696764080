import React, { useState, useEffect, useRef } from 'react';
import { Tab, Tabs, Accordion, Button, Offcanvas } from 'react-bootstrap';
import {GetCms} from '../../Hooks/UseBackend'


const Codedetail = (props) => {
    const [cmsdata, setCmsdata] = useState({});
    const [codedetail, setCodedetail] = useState(true)
    const CmsData = async () => {
        const  result  = await GetCms()
        console.log("resultresult",result);
        if (result) {
          setCmsdata(result)
        }
    
      }
      useEffect(() => {
        CmsData()
    }, [])
    return (

        <>

            <div className='viewcanvas'>
                <Offcanvas show={codedetail} placement='end' name="end">
                    <Offcanvas.Header>
                        <h3 className='fw-600'>PingPong.sol</h3>
                        <button className='btn btn-link themeclr close' onClick={() => props.onDismiss()}><span className='fa fa-times'></span></button>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        {/* <h4 className='fw-500'>The cross chain </h4> */}
                       { console.log("sdsd",cmsdata[11]?.content)}
                        {`${cmsdata[11]?.identifier == "content3" ? cmsdata[11]?.content : <></>}`}
                        <br></br><br></br>


                        {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                        <span style={{color: "#57fff1"}}>Ut enim ad
                        minim veniam.
                        </span> */}

                        {/* <Button className='w-100' variant="dark" >View More Examples</Button> */}
                    </Offcanvas.Body>
                </Offcanvas>
            </div>


        </>
    )
}

export default Codedetail;
